import {
  Avatar as AvatarChakra,
  AvatarProps,
  forwardRef,
} from '@chakra-ui/react';
import { FC } from 'react';

import IconUser from '@ui-kit/icons/icon-user';

export const Avatar: FC<AvatarProps> = forwardRef((props, ref) => {
  return (
    <AvatarChakra
      userSelect='none'
      ref={ref}
      icon={<IconUser />}
      {...props}
    />
  );
});

export default Avatar;
